<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <div class="text-center">
                  <h5 class="text-dark"><i class="ri-edit-line"></i> {{ $t('dealer_panel.ferti_req') }}</h5>
              </div>
          </template>
          <template v-slot:body>
            <profile-status-msg :preConditionLoader="preConditionLoader" :profileStatus="profileStatus" :profile-url="''" :inForm="true"/>
            <b-overlay :show="false">
            <b-row>
              <b-col lg="12" sm="12" class="form-for-textarea" @click="displayProfileCheckAlert">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                    <b-row>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('warehouse_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Requisition Date" vid="requisition_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="requisition_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('pump_install.requisition_date')}} <span class="text-danger">*</span>
                              </template>
                               <b-form-input
                                  class="date-picker"
                                  v-model="formData.requisition_date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Month"  vid="month" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="month"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('pusti_mapping.month')}} <span class="text-danger">*</span>
                              </template>
                               <b-form-select
                                plain
                                v-model="formData.month"
                                :options="monthList"
                                id="month"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Allocation Type"  vid="allocation_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="allocation_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('fertilizerSales.allocationType')}}  <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.allocation_type_id"
                                :options="allocationTypeList"
                                id="allocation_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                      <div class="col text-right">
                        <span class="text-right text-dark font-weight-bold"> {{ $t('fertilizerReport.accountMayTons') }}</span>
                      </div>
                    </b-row>
                    <b-table-simple class="mt-3" hover bordered small caption-top responsive>
                      <b-thead head-variant="secondary">
                        <b-tr>
                          <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                          <b-th>{{ $t('fertilizerConfig.fertilizer_name') }}</b-th>
                          <b-th>{{ $t('globalTrans.quantity') }}</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr v-for="(portInfoItem, index) in formData.requisition_details" :key="index">
                          <b-th>{{ $n(index+1) }}</b-th>
                          <b-th>{{ currentLocale === 'en' ? portInfoItem.fertilizer_name : portInfoItem.fertilizer_name_bn }}</b-th>
                          <b-th class="text-right">
                            <b-form-group
                            >
                              <b-form-input
                                  :id="'allocated_amount_'+(index+1)"
                                  :ref="'allocated_amount_'+(index+1)"
                                  type="number"
                                  step="0.01"
                                  v-model="portInfoItem.quantity"
                              ></b-form-input>
                            </b-form-group>
                          </b-th>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                    <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
.form-for-textarea textarea{ line-height: 18px !important;height: 54px !important; }
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import ProfileStatusMsg from '@/modules/external-user-service/irrigation/components/ProfileStatusMsg'
import { requisitionStore, requisitionUpdate } from '../../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider,
    ProfileStatusMsg
  },
  created () {
    this.checkDealerUser()
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.formData.requisition_details = []
      const fertilizerDetailsInfo = tmp.dealer_requisition_details
      this.fertilizerNameList.filter(item => {
        const fertiDetailsItem = fertilizerDetailsInfo.find(portDetailsObj => portDetailsObj.fertilizer_id === item.value)
        const portInfoItem = {
          requisition_details_id: typeof fertiDetailsItem !== 'undefined' ? fertiDetailsItem.id : 0,
          fertilizer_id: item.value,
          quantity: typeof fertiDetailsItem !== 'undefined' ? fertiDetailsItem.quantity : '',
          fertilizer_name: item.text_en,
          fertilizer_name_bn: item.text_bn
        }
        this.formData.requisition_details.push(portInfoItem)
      })
    } else {
      this.fertilizerNameList.filter(item => {
        const portInfoItem = {
          fertilizer_id: item.value,
          quantity: '',
          fertilizer_name: item.text_en,
          fertilizer_name_bn: item.text_bn
        }
        this.formData.requisition_details.push(portInfoItem)
      })
    }
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      profileStatus: 0,
      preConditionLoader: false,
      formData: {
        id: '',
        fiscal_year_id: 0,
        allocation_type_id: 0,
        month: 0,
        requisition_details: []
      }
    }
  },
  computed: {
    fertilizerNameList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    allocationTypeList: function () {
        const type = this.$store.state.ExternalUserIrrigation.commonObj.allocationTypeList.filter(item => item.status === 1)
        const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    },
    currentLocale: function () {
      return this.$i18n.locale
    },
    fiscalYearList: function () {
      const fiscalyear = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
      return fiscalyear.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    monthList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.monthListfiscalYear
    }
  },
  watch: {
  },
  methods: {
    checkDealerUser () {
      this.preConditionLoader = true
      RestApi.getData(seedFertilizerServiceBaseUrl, 'dealer-panel/profile').then(response => {
        if (response.success) {
          // profile status is converted to match message with profile status msg component
          this.profileStatus = 4
        } else {
          this.profileStatus = 0
        }
        this.preConditionLoader = false
      })
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
      if (this.profileStatus === 0) {
          this.displayProfileCheckAlert()
          return
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${requisitionUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, requisitionStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.success({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        }
      }
    },
    displayProfileCheckAlert () {
      if (this.profileStatus !== 0) {
          return
      }
      // const msg = this.$t('externalPanel.profile_complete_msg_part1') + '<a href="/irrigation/my-profile">links</a> ' + this.$t('externalPanel.profile_complete_msg_part2')
      this.$swal({
          title: this.$t('externalPanel.unauthorized_message'),
          showCloseButton: true,
          confirmButtonText: 'Close',
          // showCancelButton: true,
          // html: msg
          focusConfirm: false
      })
    }
  }
}
</script>
